<nav [ngClass]="{ details: sideBarShown, localhost: platform !== '', mobile: isMobile }">
  <ng-container>
    <div class="row toggler">
      <div class="item-container" (click)="toggle()">
        <div class="icon">
          <i class="pi pi-bars"></i>
        </div>
      </div>
    </div>
    <p-menu [model]="navItems" routerLinkActive="active" (click)="menuClick()"></p-menu>
  </ng-container>

  <app-auth-menu></app-auth-menu>
  <div *ngIf="platform !== 'PROD'" class="platform" (click)="toggleDevCaching()">
    @if (isDevCaching) { !! }{{ platform }}@if (isDevCaching) { !! }
  </div>
</nav>
<button
  type="button"
  pButton
  icon="pi pi-bars"
  (click)="toggle()"
  *ngIf="isMobile && !sideBarShown"
  class="nav-btn"
></button>

<p-dropdown
  [options]="options"
  [(ngModel)]="selectedOption"
  (onChange)="onChangeEvent($event)"
  dataKey="id"
  optionLabel="label"
  optionDisabled="optionDisabled"
  [placeholder]="placeholder"
  [required]="required"
  [showClear]="showClear"
  [filter]="filter"
  [disabled]="formDisabled"
  [scrollHeight]="scrollHeight"
  appendTo="body"
  [style]="style"
  [readonly]="readonly"
  [ngClass]="{ readonly: readonly }"
></p-dropdown>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"
import { AngularFirestore, DocumentReference } from "@angular/fire/compat/firestore"

import { ReferentialService } from "@app/pages/referential/referential.service"

@Component({
  selector: "app-dropdown-firestore",
  templateUrl: "./dropdown-ref-firestore.component.html",
  styleUrls: ["./dropdown-ref-firestore.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownRefFirestoreComponent,
    },
  ],
})
export class DropdownRefFirestoreComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() style = {}
  @Input() autoFetchData = false
  @Input() referentialKey = ""
  @Input() required = false
  @Input() showClear = true
  @Input() filter = true
  @Input() disabled = false
  @Input() scrollHeight = "200px"
  @Input() value: any = null
  @Input() placeholder = "Sélectionner..."
  @Input() readonly = false

  selectedOption?: GenericDropDownView

  private idWritten: string | null = null

  // form related data
  formTouched = false
  formDisabled = false
  onChangeForm = (ref?: DocumentReference<unknown>) => {}
  onTouchedForm = () => {}

  @Input() fetchData?: () => Promise<GenericDropDownView[]>
  // if fetchData is null, taking options (synchronously)
  private _options: GenericDropDownView[] = []
  @Input() set options(value: GenericDropDownView[]) {
    this._options = value
    this.idWritten = this.value
    this.selectFromWritten()
  }

  get options(): GenericDropDownView[] {
    return this._options
  }

  @Output() change = new EventEmitter<string>()

  constructor(private firestore: AngularFirestore, private refService: ReferentialService) {}

  ngOnInit(): void {
    if (this.autoFetchData && this.referentialKey) {
      this.refService.getAllFromCache(this.referentialKey as any).then((x) => {
        this.options = x.map((value) => ({
          id: value.id,
          label: `${value.name}`,
        }))
        this.idWritten = this.value
        this.selectFromWritten()
      })
    } else if (this.fetchData) {
      this.fetchData().then((options) => {
        this.options = options
        this.idWritten = this.value
        this.selectFromWritten()
      })
    }

    if (!this.referentialKey) {
      throw new Error("referentialKey should no be null (DropdownRefFirestoreComponent)")
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("changes", changes)
    const disableChange = changes.disabled
    if (disableChange) {
      this.formDisabled = disableChange.currentValue
    }
  }

  onChangeEvent($event: any): void {
    if (!this.formDisabled) {
      this.markAsTouched()
      if (this.selectedOption?.id) {
        const ref = this.firestore.doc(this.referentialKey + "/" + this.selectedOption?.id).ref
        this.onChangeForm(ref)
      } else {
        this.onChangeForm(null as any)
      }
      this.change.emit(this.selectedOption?.id)
    }
  }

  private markAsTouched(): void {
    if (!this.formTouched) {
      this.onTouchedForm()
      this.formTouched = true
    }
  }

  private selectFromWritten(): void {
    if (this.idWritten) {
      const found = this.options.find((v) => v.id === this.idWritten)
      if (found) {
        this.selectedOption = found
      } else if (this.idWritten === "rights_issue") {
        this.options.push({
          label: "xxxx (droits insuffisants)",
          id: this.idWritten,
          optionDisabled: true,
        })
        this.selectedOption = this.options.find((v) => v.id === this.idWritten)
      }
    } else {
      this.selectedOption = undefined
    }
  }

  registerOnChange(fn: any): void {
    // console.log("registerOnChange", fn)
    this.onChangeForm = fn
  }

  registerOnTouched(fn: any): void {
    // console.log("registerOnTouched", fn)
    this.onTouchedForm = fn
  }

  setDisabledState(isDisabled: boolean): void {
    // console.log("setDisabledState", isDisabled)
    this.formDisabled = isDisabled
  }

  writeValue(id: any): void {
    // console.log("writeValue", id)
    if (id && id.id2) {
      this.idWritten = id.id2
      this.selectFromWritten()
    } else if (id && id.get) {
      id.get()
        .then((val: any) => {
          this.idWritten = val.id
          this.selectFromWritten()
        })
        .catch((error: any) => {
          if (error?.message?.includes("Missing or insufficient permissions")) {
            this.idWritten = "rights_issue"
            this.selectFromWritten()
          }
        })
    } else if (!id) {
      this.idWritten = ""
      this.selectFromWritten()
    }
  }
}

export interface GenericDropDownView {
  id: string
  label: string
  optionDisabled?: boolean
}

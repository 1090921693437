<div class="header justify-content-center flex align-items-center">
  <div style="max-width: 33vw">Mes représentations</div>
  <p-progressSpinner *ngIf="loadingCache" [style]="{ width: '20px', height: '20px' }" class="ml-2"></p-progressSpinner>
  <p-selectButton
    [options]="filterOptions"
    [(ngModel)]="filterValue"
    optionLabel="label"
    class="ml-2"
    optionValue="value"
    (ngModelChange)="filterChange()"
  ></p-selectButton>

  <div class="absolute font-normal text-gray-400 timeLoaded" style="top: 10px; right: 10px">{{ timeDataLoaded }}</div>
</div>
<div *ngIf="loading" class="spinner-container flex align-items-center justify-content-center">
  <p-progressSpinner [style]="{ width: '30vw', height: '30vw' }" [strokeWidth]="'3px'"></p-progressSpinner>
</div>
<div class="content">
  <div *ngIf="filteredPerfs.length === 0 && !loading" class="mt-3">
    Vous n'êtes affecté à aucune représentation dans le {{ filterValue ? "futur" : "passé" }}.
  </div>

  <p-accordion (onOpen)="loadDetails($event)" [(activeIndex)]="activeIndex">
    <p-accordionTab
      *ngFor="let perf of filteredPerfs; trackBy: trackByPerfId"
      [class]="perf.color"
      [ngClass]="{ past: perf.date <= now, next: nextPerfId === perf.perfId, cached: perf.hasCache }"
    >
      <ng-template pTemplate="header">
        <div class="accordion-header" style="width: calc(100vw - 30px)">
          <div class="flex justify-content-between align-items-center">
            <div class="text-center flex-1">
              <div>{{ perf.dateStr }}</div>
              <div *ngIf="perf.statusPerf" [style]="'color:' + perf.statusPerfColor" class="font-italic">
                {{ perf.statusPerf }}
              </div>
              <!--            <div class="font-bold">{{ perf.codeName }}</div>-->
            </div>
            <div class="text-center" style="flex: 2 1 0%">
              <div *ngIf="perf.program">
                {{ perf.program }}
              </div>
              <div *ngIf="perf.town" class=""><i class="pi pi-map-marker pt-1 mr-1"></i>{{ perf.town }}</div>
              <div class="font-italic text-gray-600">{{ perf.typePerf }}</div>
            </div>
            <div class="text-center flex-1 text-gray-700" style="max-width: 25%">
              <div>
                <i>{{ perf.skills }}</i>
              </div>
              <i>
                <div>
                  <span [style]="'color:' + perf.personStatusColor" *ngIf="perf.personStatus"
                    >{{ perf.personStatus }}
                  </span>
                </div>
                <!--                <div class="ellipsis">{{ perf.note }}</div>-->
              </i>
            </div>
            <div (click)="gotoCalendar(perf); $event.stopPropagation()" class="btn-calendar" *ngIf="perf.showPlanning">
              <i
                class="pi pi-calendar"
                [style]="
                  authService.isOffline
                    ? perf.hasCalendarCache
                      ? ''
                      : 'opacity:0.1'
                    : perf.hasCalendarCache
                    ? 'color:green'
                    : ''
                "
                title="Voir le calendrier"
              ></i>
            </div>
          </div>
          <div
            *ngIf="perf.general_note"
            class="flex justify-content-center font-italic darkblue mt-1 pl-2"
            style="min-height: 14px"
          >
            {{ perf.general_note }}
          </div>
          <div
            *ngIf="perf.note_prio"
            class="flex justify-content-center font-italic darkblue mt-1 font-bold pl-2"
            style="min-height: 14px"
            [innerHTML]="perf.note_prio"
          ></div>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="-mt-5">
          <div
            *ngFor="let detail of detailsDisplayed"
            [innerHTML]="detail"
            class="flex flex-row align-items-center"
          ></div>
          @if (detailsNotes.length > 0) {
          <div>
            <h3 class="note"><b>Notes:</b></h3>
          </div>
          <app-extern-notes-display [notes]="detailsNotes" [extern]="true"> </app-extern-notes-display>
          }
        </div>
        <div
          *ngIf="detailsDisplayed.length === 0"
          class="font-italic mt-5 flex justify-content-center align-items-center font-italic"
        >
          Aucune information
        </div>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</div>

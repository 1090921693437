<div [ngClass]="{ full: full, extern: extern }">
  @if (full) {
  <!--Header-->
  <div class="header flex align-items-center text-xs mb-4">
    <i class="pi pi-pencil" [style.visibility]="'hidden'"></i>
    <div class="text-gray-400 date date2">Timestamp</div>
    <div class="text-blue-400 ml-2 date datePublic">Date publique</div>
    <div class="type">Type</div>
    <div class="statusRef">Statut personne</div>
    <div class="text">Note</div>
  </div>
  } @if (full || extern) { @for (note of notes; track note.date) {
  <div class="flex align-items-center text-xs note">
    <i
      class="pi pi-pencil"
      (click)="openUpdateNote(note)"
      [style.visibility]="full && note.type !== 'status' ? '' : 'hidden'"
    ></i>
    <div class="text-gray-400 date date2" [title]="note.date | date : 'DD/MM/YYYY à HH:mm:ss'">
      {{ note.date | date : "DD/MM/YYYY à HH:mm" }}
    </div>
    <div class="text-blue-400 ml-2 date datePublic" [title]="note.datePublic | date : 'DD/MM/YYYY à HH:mm:ss'">
      {{ note.datePublic | date : "DD/MM/YYYY" }}
    </div>
    <div class="type" [ngClass]="note.type">{{ mapTypeLabel[note.type] }}</div>
    <div class="statusRef" [ngClass]="note.type">{{ mapStatusPersons[note.statusRef?.id || ""] }}</div>
    <div class="text" [ngClass]="note.type">{{ note.text }}</div>
  </div>
  } } @else {
  <div class="flex flex-column justify-content-center" style="height: 50px">
    @for (note of notesTwoLines; track note.date; let i = $index) {
    <div class="flex align-items-center text-xs note">
      <div class="text-gray-400 date date2" [title]="note.date | date : 'DD/MM/YYYY à HH:mm:ss'">
        {{ note.date | date : "DD/MM/YYYY à HH:mm" }}
      </div>
      <div class="text-blue-400 ml-2 date datePublic" [title]="note.datePublic | date : 'DD/MM/YYYY à HH:mm:ss'">
        {{ note.datePublic | date : "DD/MM/YYYY" }}
      </div>
      <!--<div class="statusRef" [ngClass]="note.type">{{ mapStatusPersons[note.statusRef?.id || ""] }}</div>-->
      <p-badge *ngIf="countNotesPrio && i === 0" class="badge-prio" [value]="countNotesPrio" />
      <div class="text ellipsis" [ngClass]="note.type" [title]="note.text">{{ note.text }}</div>
    </div>
    }
  </div>
  }
</div>

import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { CalendarModule } from "primeng/calendar"
import { SidebarModule } from "primeng/sidebar"
import { MenuModule } from "primeng/menu"
import { BreadcrumbModule } from "primeng/breadcrumb"
import { ToastModule } from "primeng/toast"
import { ConfirmDialogModule } from "primeng/confirmdialog"
import { ConfirmationService } from "primeng/api"
import { DialogService, DynamicDialogModule } from "primeng/dynamicdialog"
import { InputTextModule } from "primeng/inputtext"
import { ButtonModule } from "primeng/button"
import { ControlErrorsDirective } from "@shared/directives/control-errors.directive"
import { FormFieldComponent } from "@shared/component/form-field/form-field.component"
import { FormSubmitComponent } from "@shared/component/form-submit/form-submit.component"
import { DialogModule } from "primeng/dialog"
import { DragDropModule } from "primeng/dragdrop"
import { ChipModule } from "primeng/chip"
import { InputTextareaModule } from "primeng/inputtextarea"
import { ColorPickerModule } from "primeng/colorpicker"
import { TimePickerComponent } from "@shared/component/time-picker/time-picker.component"
import { DropdownModule } from "primeng/dropdown"
import { TabViewModule } from "primeng/tabview"
import { CheckboxModule } from "primeng/checkbox"
import { FileUploadModule } from "primeng/fileupload"
import { AngularFireModule } from "@angular/fire/compat"
import { environment } from "@env/environment"
import { ListComponent } from "@shared/component/list/list.component"
import { TableModule } from "primeng/table"
import { DeferLoadDirective } from "@shared/directives/defer-load"
import { DropdownRefFirestoreComponent } from "@shared/component/dropdown-ref-firestore/dropdown-ref-firestore.component"
import { OrderListModule } from "primeng/orderlist"
import { SelectButtonModule } from "primeng/selectbutton"
import { ProgressSpinnerModule } from "primeng/progressspinner"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MultiSelectModule } from "primeng/multiselect"
import { DatePipe2 } from "@shared/directives/date-pipe2"
import { ViewsListComponent } from "./component/views-list/views-list.component"
import { MultiselectRefFirestoreComponent } from "@shared/component/multiselect-ref-firestore/multiselect-ref-firestore.component"
import { OverlayPanelModule } from "primeng/overlaypanel"
import { AccordionModule } from "primeng/accordion"
import { ContextMenuModule } from "primeng/contextmenu"
import { SplitButtonModule } from "primeng/splitbutton"
import { RadioButtonModule } from "primeng/radiobutton"
import { ContactFieldComponent } from "@shared/contact/contact-field/contact-field.component"
import { ContactFormComponent } from "@shared/contact/contact-form/contact-form.component"
import { FieldsetModule } from "primeng/fieldset"
import { BadgeModule } from "primeng/badge"

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    DialogModule,
    CalendarModule,
    SidebarModule,
    MenuModule,
    BreadcrumbModule,
    ToastModule,
    ConfirmDialogModule,
    DropdownModule,
    CheckboxModule,
    ToastModule,
    FileUploadModule,
    TableModule,
    MultiSelectModule,
    InputTextModule,
    ChipModule,
    SelectButtonModule,
    SplitButtonModule,
    InputTextareaModule,
    RadioButtonModule,
    FieldsetModule,
    BadgeModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  declarations: [
    DeferLoadDirective,
    ControlErrorsDirective,
    FormFieldComponent,
    FormSubmitComponent,
    TimePickerComponent,
    ListComponent,
    DropdownRefFirestoreComponent,
    MultiselectRefFirestoreComponent,
    DatePipe2,
    ViewsListComponent,
    ContactFieldComponent,
    ContactFormComponent,
  ],
  exports: [
    DeferLoadDirective,
    ControlErrorsDirective,
    FormFieldComponent,
    FormSubmitComponent,
    TimePickerComponent,
    ListComponent,
    DropdownRefFirestoreComponent,
    MultiselectRefFirestoreComponent,
    ContactFieldComponent,
    ContactFormComponent,

    // PrimeNG
    CalendarModule,
    SidebarModule,
    MenuModule,
    BreadcrumbModule,
    ToastModule,
    InputTextModule,
    ButtonModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    ChipModule,
    InputTextareaModule,
    ColorPickerModule,
    DropdownModule,
    TabViewModule,
    CheckboxModule,
    ToastModule,
    FileUploadModule,
    OrderListModule,
    SelectButtonModule,
    ProgressSpinnerModule,
    DialogModule,
    TableModule,
    OverlayPanelModule,
    AccordionModule,
    ContextMenuModule,
    FieldsetModule,

    DatePipe2,
    ViewsListComponent,
    RadioButtonModule,
    BadgeModule,
  ],
  providers: [ConfirmationService, DialogService],
})
export class SharedModule {}

import { Component, inject, Input, OnInit } from "@angular/core"
import { ExternNote, mapExternNoteTypeLabel, PerformanceDTO } from "@app/pages/performance/peformance.service"
import { REFERENTIAL, ReferentialService } from "@app/pages/referential/referential.service"
import { ExternPopupNoteComponent } from "@app/pages/person/communication/extern-popup-note/extern-popup-note.component"
import { DialogService } from "primeng/dynamicdialog"

@Component({
  selector: "app-extern-notes-display",
  templateUrl: "./extern-notes-display.component.html",
  styleUrl: "./extern-notes-display.component.scss",
})
export class ExternNotesDisplayComponent implements OnInit {
  _notes: Array<ExternNote> = []
  @Input() set notes(value: Array<ExternNote>) {
    this._notes = value
    if (!value) return
    const notesPrios = value.filter((s) => s.type === "prio")
    const notesTwoLines = notesPrios.length > 0 ? [notesPrios[0]] : []
    for (let i = notesTwoLines.length; i < 2; i++) {
      const notes = value.filter((s) => s.type !== "prio" && !notesTwoLines.find((n) => n.id === s.id))
      if (notes.length > 0) notesTwoLines.push(notes[0])
    }
    this.notesTwoLines = notesTwoLines
    this.countNotesPrio = notesPrios.length > 1 ? `${notesPrios.length}` : ""
  }

  get notes(): Array<ExternNote> {
    return this._notes
  }

  @Input() full = false // tue => in popup with history
  @Input() extern = false
  @Input() performance?: PerformanceDTO
  @Input() personId = ""

  private refService = inject(ReferentialService)

  notesTwoLines: Array<ExternNote> = []
  countNotesPrio = ""
  mapStatusPersons = {}
  mapTypeLabel = mapExternNoteTypeLabel

  constructor(private dialogService: DialogService) {}

  async ngOnInit() {
    const statusPersons = await this.refService.getAllFromCache(REFERENTIAL.person_status)
    statusPersons.forEach((status) => {
      this.mapStatusPersons[status.id] = status.name
    })
  }

  openUpdateNote(note: ExternNote) {
    this.dialogService.open(ExternPopupNoteComponent, {
      header: "Modifier une note",
      width: "600px",
      data: {
        note: note,
        personId: this.personId,
        performance: this.performance,
      },
    })
  }
}

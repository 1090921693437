<div class="flex flex-column align-items-center">
  @if (note) {
  <div class="m-4">
    <p-calendar appendTo="body" inputId="calendar-24h" [(ngModel)]="dateModel" [showTime]="true" [hourFormat]="'24'" />
  </div>
  }@else{
  <app-dropdown-firestore
    [referentialKey]="REFERENTIAL.person_status"
    [(ngModel)]="statusModel"
    [options]="personStatus"
  ></app-dropdown-firestore>
  }
  <div class="m-4">
    <div class="flex xflex-wrap gap-1 radio">
      @for (type of noteTypes; track type; let index = $index) {
      <div class="flex align-items-center">
        <p-radioButton name="xxx" [value]="type" [(ngModel)]="typeModel" [inputId]="'type' + index" />
        <label [for]="'type' + index" class="ml-2">
          {{ mapExternNoteTypeLabel[type] }}
        </label>
      </div>
      }
    </div>
  </div>

  <textarea [rows]="5" placeholder="Note" style="width: 300px" pInputTextarea [(ngModel)]="textModel"></textarea>
</div>

<div class="flex justify-content-around mt-5">
  <div class="">
    <button class="p-button-outlined" label="Annuler" pButton (click)="close()"></button>
  </div>
  <div class="btn_publish">
    @if (note) {
    <button label="Modifier" pButton (click)="update()" [disabled]="textModel == ''"></button>
    } @else {
    <button label="Publier" pButton (click)="publish()" [disabled]="textModel == ''"></button>
    }
  </div>
</div>

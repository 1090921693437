import { EventEmitter, Injectable } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { PersonDTO, PersonService } from "@app/pages/person/person.service"
import { UserService } from "@app/pages/person/user.service"
import { AngularFireFunctions } from "@angular/fire/compat/functions"
import { Observable } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentUser: PersonDTO | undefined
  public isOffline = false
  onlineStatusUpdated$ = new EventEmitter<boolean>()

  createUserCloudFunction!: (d: any) => Observable<any>
  updateUserPwdCloudFunction!: (d: any) => Observable<any>
  updateRoleCloudFunction!: (d: any) => Observable<any>
  synchronizeGoogleCalendar!: (d: any) => Observable<any>

  constructor(
    public afAuth: AngularFireAuth,
    private personService: PersonService,
    private userService: UserService,
    private functions: AngularFireFunctions
  ) {
    this.createUserCloudFunction = this.functions.httpsCallable("createUser")
    this.updateUserPwdCloudFunction = this.functions.httpsCallable("updateUserPwd")
    this.updateRoleCloudFunction = this.functions.httpsCallable("updateRole")

    this.synchronizeGoogleCalendar = this.functions.httpsCallable("synchronizeGoogleCalendar", {
      timeout: 9 * 60 * 1000,
    })

    setTimeout(() => {
      afAuth.authState.subscribe(() => this.getUserInfo())
    }, 2000) // the first call of getUserInfo coming from authguard, we're waiting in order to get the info from the cache and avoid 2 calls

    setInterval(() => {
      const isOffline = navigator.onLine === false
      if (isOffline !== this.isOffline) {
        this.isOffline = isOffline
        this.onlineStatusUpdated$.next(isOffline)
      }
    }, 2000)
  }

  getUserInfo(force = false): Promise<PersonDTO> {
    if (this.currentUser && !force) {
      // console.log("getUserInfo", force, this.currentUser)
      return Promise.resolve(this.currentUser)
    }
    return this.afAuth.currentUser.then((user) => {
      if (!user?.uid) return {} as PersonDTO
      return this.personService.getPersonFromFirebaseUid(user?.uid).then((person) => {
        this.currentUser = person
        this.currentUser.email = user?.email || ""
        this.currentUser.uuid = user?.uid
        this.personService.setUuid(this.currentUser.uuid)
        // console.log("getUserInfo2", force, this.currentUser)
        return this.currentUser
      })
    })
  }

  hasRolePlanning(): boolean {
    return this.currentUser?.rolePlanning === true
  }

  isAdmin(): boolean {
    return this.currentUser?.admin === true
  }

  getMyPersonId(): string {
    return this.currentUser?.id || this.currentUser?.uuid || ""
  }

  login(cmd: LoginCmd): Promise<any> {
    return this.afAuth.signInWithEmailAndPassword(cmd.username, cmd.password)
  }

  logout(): Promise<void> {
    return this.afAuth.signOut().then(() => {
      this.currentUser = {} as PersonDTO
      this.currentUser.uuid = ""
      window.location.reload() // TO BE IMPROVED => a quick way for deleting all cache!
    })
  }

  isAuthenticated(): Promise<boolean> {
    return this.afAuth.currentUser.then((u) => !!u)
  }

  updateUserPassword(uid: string, email: string, password: string): Promise<unknown> {
    return this.updateUserPwdCloudFunction({
      uid,
      email,
      password,
    }).toPromise()
  }

  updateRole(uid: string, role: string): Promise<unknown> {
    return this.updateRoleCloudFunction({
      uid,
      role,
    }).toPromise()
  }

  createUser(email: string, password: string, personId: string): Promise<unknown> {
    return this.createUserCloudFunction({ email, password, personId })
      .toPromise()
      .then(
        () =>
          this.userService.invalidateCache() /*FIXME => update cache when a new user is created instead of invalidate !! */
      )
  }

  updatePassword(password: string): Promise<any> {
    return this.afAuth.currentUser.then((user) => user?.updatePassword(password))
  }

  resetPassword(email: string): Promise<any> {
    return this.afAuth.sendPasswordResetEmail(email)
  }
}

export interface LoginCmd {
  username: string
  password: string
}

export interface LoginDto {
  username: string
  token: string
}

import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { environment } from "@env/environment"
import { AuthService } from "@app/auth"
import { Utils } from "@shared/util/utils"

interface NavItem {
  id: string
  routerLink: string
  label: string
  icon: string
  disabled?: boolean
}

@Component({
  selector: "app-side-bar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"],
})
export class SideBarComponent implements OnInit {
  isMobile = Utils.isMobile()
  isDevCaching = localStorage["caching"] || false

  public readonly navAllItems: Array<NavItem> = [
    {
      id: "planning",
      routerLink: "/planning",
      label: "Plannings",
      icon: "pi pi-calendar",
    },
    {
      id: "person",
      routerLink: "/person",
      label: "Personnes",
      icon: "pi pi-users",
    },
    // {
    //   id: "organization",
    //   routerLink: "/organization",
    //   label: "Structures",
    //   icon: "pi pi-building",
    // },
    {
      id: "crm",
      routerLink: "/crm",
      label: "Droits",
      icon: "pi pi-lock",
    },
    {
      id: "performance",
      routerLink: "/performance",
      label: "Représentations",
      icon: "pi pi-star-fill",
    },
    {
      id: "program",
      routerLink: "/program",
      label: "Programmes",
      icon: "pi pi-bookmark-fill",
    },
    {
      id: "unit",
      routerLink: "/unit",
      label: "Pièces",
      icon: "pi pi-list",
    },
    {
      id: "seniority",
      routerLink: "/seniority",
      label: "Ancienneté",
      icon: "pi pi-history",
    },
    {
      id: "da-dd",
      routerLink: "/remuneration/",
      label: "Rémunération",
      icon: "pi pi-euro",
    },
    {
      id: "referential",
      routerLink: "/referential",
      label: "Référentiels",
      icon: "pi pi-cog",
    },
    {
      id: "",
      routerLink: "",
      label: "",
      icon: "",
    },
    {
      id: "extern",
      routerLink: "/extern-admin",
      label: "Vue externe",
      icon: "pi pi-eye",
    },
  ].map((x: any) => {
    if (x.id !== "extern") x.disabled = this.authService.isOffline
    return x
  })

  @Output() opening = new EventEmitter()
  navItems: Array<NavItem> = []
  sideBarShown = false
  platform = environment.production ? "" : "DEV"

  constructor(public authService: AuthService) {
    this.navItems = this.navAllItems
    this.sideBarShown = localStorage.getItem("sidebarOpened") === "true"
    this.authService.onlineStatusUpdated$.subscribe((isOffline) => {
      this.navItems.forEach((item) => {
        if (item.id !== "extern") item.disabled = isOffline
      })
      this.navItems = [...this.navItems]
    })
  }

  ngOnInit(): void {
    this.authService.getUserInfo().then(() => {
      if (this.authService.hasRolePlanning()) {
        this.navItems = this.navItems.filter((x) => ["performance", "planning", "extern", ""].includes(x.id))
      }
      if (!this.authService.currentUser?.id) {
        this.navItems = this.navItems.filter((x) => x.id !== "extern")
      }
    })
  }

  toggle(): void {
    this.sideBarShown = !this.sideBarShown
    this.opening.emit(this.sideBarShown)
    localStorage.setItem("sidebarOpened", `${this.sideBarShown}`)
  }

  menuClick(): void {
    if (this.isMobile) {
      this.sideBarShown = false
    }
  }

  toggleDevCaching() {
    this.isDevCaching = !this.isDevCaching
    localStorage["caching"] = this.isDevCaching ? true : ""
    window.location.reload()
  }
}

import { Component } from "@angular/core"
import {
  ExternNote,
  ExternNoteType,
  PerformanceDTO,
  PerformanceService,
} from "@app/pages/performance/peformance.service"
import { GenericDropDownView } from "@shared/component/dropdown-ref-firestore/dropdown-ref-firestore.component"
import { REFERENTIAL, ReferentialService } from "@app/pages/referential/referential.service"
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog"
import { DocumentReference } from "@angular/fire/compat/firestore"

@Component({
  selector: "app-extern-popup-note",
  templateUrl: "./extern-popup-note.component.html",
  styleUrl: "./extern-popup-note.component.scss",
})
export class ExternPopupNoteComponent {
  performance!: PerformanceDTO
  personId = ""

  personStatus: Array<GenericDropDownView> = []
  noteTypes = ["prio", "normal", "internal"]
  mapExternNoteTypeLabel = {
    normal: "Normal",
    prio: "Prioritaire",
    internal: "Interne",
  }
  typeModel: ExternNoteType = "normal"
  textModel = ""
  dateModel = new Date()
  statusModel?: DocumentReference<unknown>

  note?: ExternNote

  constructor(
    private performanceService: PerformanceService,
    private refService: ReferentialService,
    public dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {}

  async ngOnInit(): Promise<any> {
    this.personId = this.dialogConfig.data?.personId
    this.performance = this.dialogConfig.data?.performance
    this.note = this.dialogConfig.data?.note

    if (!this.performance) throw new Error("ExternPopupNoteComponent => performance should not be null")

    if (this.note) {
      this.textModel = this.note.text
      this.typeModel = this.note.type
      this.dateModel = this.note.datePublic
    } else {
      if (this.performance.communication[this.personId].status?.id)
        this.statusModel = this.refService.getRefFromId(
          REFERENTIAL.person_status,
          this.performance.communication[this.personId].status?.id || ""
        )
    }

    Promise.all([this.refService.getAllFromCache(REFERENTIAL.person_status)]).then(([personsStatus]) => {
      this.personStatus = personsStatus.map((value) => ({
        id: value.id,
        label: `${value.name}`,
      }))
    })
  }

  async update() {
    if (!this.note) return
    const communication = this.performance.communication[this.personId]
    const noteToUpdate = communication.notes.find((n) => n.id === this.note?.id)

    if (!noteToUpdate) return

    noteToUpdate.text = this.textModel
    noteToUpdate.type = this.typeModel
    noteToUpdate.datePublic = this.dateModel

    communication.notes = [...communication.notes] // be sure to be updated in child components

    await this.performanceService.update({
      id: this.performance.id,
      communication: this.performance.communication,
    })

    this.close()
  }

  async publish() {
    const initialStatusId = this.performance.communication[this.personId].status?.id || ""
    const newStatusId = this.statusModel?.id
    const statusRef = initialStatusId !== newStatusId ? newStatusId : null

    const communication = this.performance.communication[this.personId]
    if (statusRef) {
      communication.status = this.statusModel
      communication.status_date = new Date()
    }

    communication.notes.unshift({
      type: this.typeModel as any,
      date: new Date(),
      datePublic: new Date(),
      text: this.textModel,
      id: this.performanceService.uuid(),
      ...(statusRef ? { statusRef: this.statusModel } : {}),
    })
    communication.notes = [...communication.notes] // be sure to be updated in child components

    await this.performanceService.update({
      id: this.performance.id,
      communication: this.performance.communication,
    })

    this.close()
  }

  close() {
    this.dialogRef.close()
  }

  protected readonly REFERENTIAL = REFERENTIAL
}

import { Injectable } from "@angular/core"
import { MessageService } from "primeng/api"

enum Severity {
  Error = "error",
  Success = "success",
  Warning = "warn",
}

@Injectable({
  providedIn: "root",
})
export class NotifService {
  constructor(private messageService: MessageService) {}

  displayError(message: string, position = "", duration = 3000): void {
    this.displayMessage(Severity.Error, message, position, duration)
  }

  displaySuccess(message: string, position = ""): void {
    this.displayMessage(Severity.Success, message, position)
  }

  displayWarning(message: string, duration = 3000): void {
    this.displayMessage(Severity.Warning, message, "", duration)
  }

  private displayMessage(severity: Severity, message: string, position = "", duration = 3000): void {
    this.messageService.add({ severity, detail: message, life: duration, key: position })
  }
}
